@tailwind base;
@tailwind components;
@tailwind utilities;

.app-background {
  background-image: linear-gradient(
    145deg,
    hsl(211deg 46% 43%) 0%,
    hsl(213deg 36% 48%) 35%,
    hsl(215deg 31% 52%) 56%,
    hsl(216deg 29% 55%) 70%,
    hsl(217deg 26% 59%) 81%,
    hsl(217deg 24% 62%) 88%,
    hsl(218deg 21% 66%) 93%,
    hsl(219deg 17% 69%) 97%,
    hsl(219deg 13% 73%) 99%,
    hsl(220deg 7% 76%) 100%,
    hsl(0deg 0% 79%) 100%
  );
  min-height: 100vh;
  width: 100%;
}

p {
  font-family: Arial, Helvetica, sans-serif;
}
